import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

export const Section = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 40px;
  font-size: 18px;
`

const DamicheleKelsterbachPage = () => (
  <Layout>
    <SEO title="Pizzeria Da Michele" />
    <Section>
      <h1>Pizzeria Da Michele</h1>
      <p>
          Die App befindet sich aktuell im Aufbau. Nach Fertigstellung werden Sie an dieser Stelle automatisch weitergeleitet.
      </p>
    </Section>
  </Layout>
)

export default DamicheleKelsterbachPage
